<template>
  <div class="result container">
    <a-result :title="title" :sub-title="subTitle">
      <template #icon>
        <img width="150" :src="image" alt="" srcset="" />
      </template>
      <template #extra>
        <van-button
          v-if="status === 'success'"
          @click="onSuccessHandler"
          class="result-btn"
          plain
          round
          color="#5D5D5D"
          >{{ $t("common.check_order") }}</van-button
        >
        <van-button
          v-if="status === 'fail' && isApp"
          @click="onFailHandler"
          class="result-btn"
          round
          color="#B50000"
          >{{ $t("common.pay_again") }}</van-button
        >
        <van-button
          v-if="!isApp"
          @click="$router.push({ name: 'Home' })"
          class="result-btn"
          round
          color="#642EC7"
          >{{ $t("common.back_home") }}</van-button
        >
      </template>
    </a-result>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Result } from "ant-design-vue";
import { handleAppActionPay, handleAppActionTopup } from "../utils/appActions";
export default {
  components: {
    [Result.name]: Result,
  },
  data() {
    return {
      title: "Result",
      subTitle: "",
      status: "",
      image: "",
    };
  },
  computed: {
    ...mapState(["isApp"]),
  },
  mounted() {
    const { order_no, r, t } = this.$route.query;
    console.log(this.$route.query);
    this.status = r === "success" ? "success" : "fail";
    this.image =
      r === "success"
        ? require(`../assets/result_success.png`)
        : require(`../assets/result_fail.png`);
    this.title =
      r === "success"
        ? this.$t("common.result_success")
        : this.$t("common.result_fail");
    document.title = this.title;
    if (r === "success") {
      const key = t === "p" ? "common.p_result_tips" : "common.c_result_tips";
      this.subTitle = this.$t(key, { text: order_no });
    }
  },
  methods: {
    onSuccessHandler() {
      const { t } = this.$route.query;
      if (this.isApp) {
        const handler = t === "p" ? handleAppActionPay : handleAppActionTopup;
        handler(1);
      } else {
        const name = t === "p" ? "Esims" : "Wallet";
        this.$router.push({ name });
      }
    },
    onFailHandler() {
      const { t } = this.$route.query;
      const handler = t === "p" ? handleAppActionPay : handleAppActionTopup;
      handler(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.result {
  padding: 100px 0;
  /deep/ .ant-result {
    .ant-result-icon {
      margin-bottom: 0;
    }
    .ant-result-title {
      color: #5d5d5d;
      font-size: 20px;
      font-weight: bold;
    }
    .ant-result-extra {
      margin-top: 20px;
    }
  }
  .result-btn {
    min-width: 133px;
  }
}
</style>
